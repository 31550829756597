import { Route, Switch } from "react-router";
import Layout from "./components/layout/Layout";
// import Home from "./components/Home";
// import Album from "./components/old-album/Album";
// import NewAlbum from "./components/NewAlbum";
// import ThirdAlbum from "./components/album/AlbumPage";
import NotFound from "./components/NotFound";
import "./custom.css";

export default function App() {
  return (
    <Layout>
      <Switch>
        {/* <Route exact path="/" component={Home} />  */}
        {/* <Route path="/album/:glob*" component={ThirdAlbum} /> */}
        {/* <Route path="/newalbum/:glob*" component={NewAlbum} /> */}
        {/* <Route path="/third/:glob*" component={Album} /> */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Layout>
  );
}
